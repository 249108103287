import {
  makeTranslatableText,
  type TranslatableText,
} from '../../../types/TranslatableText.type'

export type DeeplinkElement = {
  id: string
  position: number
  titles: TranslatableText
  type: DeeplinkElementType
  payload: any
  icon: string | undefined
  openInWebview: boolean
  privateSectionSecret: string | undefined
  defaultSegment: 'BY_SCENE' | 'BY_HOUR'
}

export class MenuElement implements DeeplinkElement {
  privateSectionSecret: string | undefined
  id = ''
  position = -1
  titles: TranslatableText = makeTranslatableText()
  type: DeeplinkElementType = DeeplinkElementType.WEBVIEW
  payload: any = undefined
  icon: string | undefined = undefined
  removable = true
  openInWebview = true
  defaultSegment: 'BY_SCENE' | 'BY_HOUR' = 'BY_SCENE'
}

export type MenuChoiceData = {
  data: string
  name: TranslatableText
  hidden: boolean
}

export type MenuChoice = MenuElement & {
  choices: MenuChoiceData[] | null
  multiselect: boolean
}

export type NavBarContent = {
  id?: string
  type: 'BOTTOM_NAV'
  elements: [
    MenuElement?,
    MenuElement?,
    MenuElement?,
    MenuElement?,
    MenuElement?,
  ]
}

export enum DeeplinkElementType {
  ABOUT = 'ABOUT',
  CASHLESS = 'CASHLESS',
  EMPTY = 'EMPTY',
  GALLERY = 'GALLERY',
  GUEST_LIST = 'GUEST_LIST',
  GUEST_LIST_TYPE = 'GUEST_LIST_TYPE',
  HOME = 'HOME',
  MAP = 'MAP',
  MENU = 'MENU',
  NOTIFICATIONS = 'NOTIFICATIONS',
  PRACTICAL_INFO = 'PRACTICAL_INFO',
  PRACTICAL_INFO_LIST = 'PRACTICAL_INFO_LIST',
  PROGRAM = 'PROGRAM',
  SAFER = 'SAFER',
  SPONSOR_LIST = 'SPONSOR_LIST',
  TICKETING = 'TICKETING',
  WEBVIEW = 'WEBVIEW',
  CHAT = 'CHAT',
}

export type DraggableChangeType<T> = {
  added:
    | {
        newIndex: number
        element: T
      }
    | undefined
  removed:
    | {
        oldIndex: number
        element: T
      }
    | undefined
  moved:
    | {
        newIndex: number
        oldIndex: number
        element: T
      }
    | undefined
}
